<template>
  <div>
    <div class="card">
      <div class="card-header">Admin</div>
      <div class="card-body" style="background-color: #f9f9f9">
        <div class="row">
          <div class="col-lg-4">
            <semipolar-spinner
              :animation-duration="2000"
              :size="100"
              color="blue"
              v-if="oneDegreeColumnLoading"
              >Spinner</semipolar-spinner
            >
            <div
              class="degress-col"
              v-if="!oneDegreeColumnLoading && oneDegreeColumn.length"
            >
              <div class="row">
                <div class="col-lg-10">
                  <h5>{{ gradoNames[0] }}</h5>
                  <admin-leads
                    :show.sync="leadModalShow"
                    :label.sync="leadModalLabel"
                    :grado.sync="gradoLabel"
                    v-bind:leads="leadModalPayload"
                    @loadleads="loadAdminLeads"
                    ref="manageAdminLead"
                  ></admin-leads>
                </div>
                <div class="col-lg-2">
                  <a
                    href="javascript:void(0)"
                    @click="showAddLeadForm(gradoNames[0])"
                  >
                    <CIcon :content="$options.plus" />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 degress-item"
                :style="{
                'max-height':`${windowHeight-300}px`,
                'height': `${windowHeight}px`,
                'overflow-y':'auto'
              }">
                  <table class="table">
                    <tbody>
                      <tr v-for="item in oneDegreeColumn" :key="item.id">
                        <td>
                          <a
                            href="javascript:void(0)"
                            @click="
                              showEditLeadForm(gradoNames[0], item)
                            "
                          >
                            <p class="head mb-1">{{ item.user_name }}</p>
                            <p class="h6">Telefono : {{ item.phone }}</p>
                            <p class="h6">Email : {{ item.email }}</p>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <semipolar-spinner
              :animation-duration="2000"
              :size="100"
              color="blue"
              v-if="twoDegreeColumnLoading"
              >Spinner</semipolar-spinner
            >
            <div
              class="degress-col"
              v-if="!twoDegreeColumnLoading && twoDegreeColumn.length"
            >̉
              <div class="row">
                <div class="col-lg-10">
                  <h5>{{ gradoNames[1] }}</h5>
                </div>
                <div class="col-lg-2">
                  <a
                    href="javascript:void(0)"
                    @click="showAddLeadForm(gradoNames[1])"
                  >
                    <CIcon :content="$options.plus" />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 degress-item"
                :style="{
                'max-height':`${windowHeight-300}px`,
                'height': `${windowHeight}px`,
                'overflow-y':'auto'
              }">
                  <table class="table">
                    <tbody>
                      <tr v-for="item in twoDegreeColumn" :key="item.id">
                        <td>
                          <a
                            href="javascript:void(0)"
                            @click="
                              showEditLeadForm(gradoNames[1], item)
                            "
                          >
                            <p class="head mb-1">{{ item.user_name }}</p>
                            <p class="h6">Telefono : {{ item.phone }}</p>
                            <p class="h6">Email : {{ item.email }}</p>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <semipolar-spinner
              :animation-duration="2000"
              :size="100"
              color="blue"
              v-if="threeDegreeColumnLoading"
              >Spinner</semipolar-spinner
            >
            <div
              class="degress-col"
              v-if="!threeDegreeColumnLoading && threeDegreeColumn.length"
              
            >
              <div class="row">
                <div class="col-lg-10">
                  <h5>{{ gradoNames[2] }}</h5>
                </div>
                <div class="col-lg-2">
                  <a
                    href="javascript:void(0)"
                    @click="showAddLeadForm(gradoNames[2])"
                  >
                    <CIcon :content="$options.plus" />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 degress-item"
                :style="{
                'max-height':`${windowHeight-300}px`,
                'height': `${windowHeight}px`,
                'overflow-y':'auto'
              }">
                  <table class="table">
                    <tbody>
                      <tr v-for="item in threeDegreeColumn" :key="item.id">
                        <td>
                          <a
                            href="javascript:void(0)"
                            @click="showEditLeadForm(gradoNames[2], item)"
                          >
                            <p class="head mb-1">{{ item.user_name }}</p>
                            <p class="h6">Telefono : {{ item.phone }}</p>
                            <p class="h6">Email : {{ item.email }}</p>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cilPlus } from "@coreui/icons";
import AdminLeads from "@/views/admin/leads/ManageLead";
import { SemipolarSpinner } from "epic-spinners";
export default {
  name: "AdminCallCenter",
  plus: cilPlus,
  components: {
    "admin-leads": AdminLeads,
    SemipolarSpinner,
  },
  data() {
    return {
      leadModalShow: false,
      leadModalLabel: null,
      gradoLabel: null,
      adminLeadsLoading: false,
      leadModalPayload: null,
      gradoNames: [
        "Máster en Ciberseguridad",
        "Máster en Medioambiente y Gestión Inteligente de la Energía",
        "Máster en Big Data. Tecnología y Analítica Avanzada",
      ],
      oneDegreeColumn: [],
      oneDegreeColumnLoading: [],
      twoDegreeColumn: [],
      twoDegreeColumnLoading: [],
      threeDegreeColumn: [],
      threeDegreeColumnLoading: [],
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    showAddLeadForm(grado, payload = {}) {
      this.leadModalLabel = "Crear";
      this.leadModalShow = true;
      this.gradoLabel = grado;
      this.leadModalPayload = "TER";
    },
    showEditLeadForm(grado, payload = {}) {
      // console.log(grado, payload);
      this.leadModalLabel = "Edit";
      this.gradoLabel = grado;
      this.leadModalPayload = payload;
      this.leadModalShow = true;
      // this.$refs.manageAdminLead.loadData();
    },
    loadAdminLeads() {
      this.adminLeadsLoading = true;
      this.oneDegreeColumnLoading = true;
      this.twoDegreeColumnLoading = true;
      this.threeDegreeColumnLoading = true;
      this.$store.dispatch("a_AdminLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_adminLeadsData;
        if (payload.length) {
          this.oneDegreeColumn = payload.records.filter(
            (item) => item.degree == 1
          );
          this.twoDegreeColumn = payload.records.filter(
            (item) => item.degree == 2
          );
          this.threeDegreeColumn = payload.records.filter(
            (item) => item.degree == 3
          );
          this.oneDegreeColumnLoading = false;
          this.twoDegreeColumnLoading = false;
          this.threeDegreeColumnLoading = false;
        } else {
          this.graph_dailyLeadsDataLoading = false;
        }
      });
    },
  },
  created() {
    this.loadAdminLeads();
  },
  computed: {
    // sessionEnabled() {
    //   return true;
    // },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  watch: {
    windowHeight(n, o) {
      // console.log("height", n, o);
    },
  },
};
</script>
<style>
.degress-col h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.degress-col .table thead td,
.degress-col .table tbody td {
  background-color: #fff !important;
  border: 1px solid #dbdfe3;
}
.degress-col .table tbody td .head {
  font-size: 16px;
  color: #969696;
}
.degress-col .table tbody td p {
  font-size: 15px;
  color: #585858;
}
.degress-item {
  /* max-height: 400px; */
  overflow-y: auto;
  /* height: 400px; */
}
a:hover {
  text-decoration: none;
}
</style>
