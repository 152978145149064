<template>
  <!-- Mnaage Lead Modal -->
  <CModal
    :title="label"
    color="default"
    :closeOnBackdrop="false"
    size="xl"
    :show="show"
    @update:show="closeSessionModal"
  >
    <div class="row" style="max-height: 350px; overflow-y: auto">
      <div class="col-lg-12">
        <h6><strong>Nombre del alumno</strong></h6>
        <h4></h4>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Nombre</label></div>
          <div class="col bdl-77">
            <input
              type="text"
              class="form-control bg-blue"
              v-model="leadsPayload.first_name"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Apellidos</label></div>
          <div class="col bdl-77">
            <input
              type="text"
              class="form-control bg-blue"
              v-model="leadsPayload.last_name"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Email</label></div>
          <div class="col bdl-77">
            <input
              type="text"
              class="form-control bg-blue"
              v-model="leadsPayload.email"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Telefono</label></div>
          <div class="col bdl-77">
            <input
              type="text"
              class="form-control bg-blue"
              v-model="leadsPayload.phone"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Grado en el que esta interesado </label>
          </div>
          <div class="col bdl-77">
            <input
              type="text"
              class="form-control bg-blue"
              readonly
              v-model="grado"
            />
          </div>
        </div>

        <h3>Gestión de la llamada</h3>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Llamadas dia 1</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsPri"
              :custom="true"
              :checked="leadsPayload.call_day_1 + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'call_day_1', e)"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Llamadas dia 2</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsPri"
              :custom="true"
              :checked="leadsPayload.call_day_2 + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'call_day_2', e)"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Llamadas dia 3</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsPri"
              :custom="true"
              :checked="leadsPayload.call_day_3 + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'call_day_3', e)"
            />
          </div>
        </div>

        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Año de nacimiento</label>
          </div>
          <div class="col bdl-77">
            <input
              type="text"
              class="form-control"
              v-model="leadsPayload.birth_year"
            />
          </div>
        </div>

        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Modalidad presencial</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsY"
              :custom="true"
              :checked="leadsPayload.presential + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'presential', e)"
            />
          </div>
        </div>

        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Horario</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsY"
              :custom="true"
              :checked="leadsPayload.schedule + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'schedule', e)"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Idioma</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsY"
              :custom="true"
              :checked="leadsPayload.languaje + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'languaje', e)"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Prácticas remuneradas</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsY"
              :custom="true"
              :checked="leadsPayload.practices + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'practices', e)"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Titulación APTA</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsY"
              :custom="true"
              :checked="leadsPayload.title + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'title', e)"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3"><label>Cierre de llamada</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="rOptionsFinalStatus"
              :custom="true"
              :checked="leadsPayload.final_status + ''"
              :inline="true"
              @update:checked="(v, e) => checkedToggleRow(v, 'final_status', e)"
            />
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Observaciones</label>
          </div>
          <div class="col bdl-77">
            <textarea
              type="text"
              class="form-control"
              rows="2"
              v-model="leadsPayload.msdyn_honorsreceived"
            ></textarea>
          </div>
        </div>

        <div class="row align-items-center mb-1" v-if="hdellRow">
          <div class="col-sm-3"><label>Horario de llamada</label></div>
          <div class="col bdl-77">
            <CInputRadioGroup
              class="col-sm-9"
              :options="hdellOptions"
              :custom="true"
              :checked="leadsPayload.schedule_time + ''"
              :inline="true"
              @update:checked="
                (v, e) => checkedToggleRow(v, 'schedule_time', e)
              "
            />
          </div>
        </div>

        <div v-if="noColaboroRow">
          <div class="row align-items-center mb-1">
            <div class="col-sm-3"><label>Motivo</label></div>
            <div class="col bdl-77">
              <CInputRadioGroup
                class="col-sm-9"
                :options="hdellOptions"
                :custom="true"
                :checked="leadsPayload.reason + ''"
                :inline="false"
                @update:checked="(v, e) => checkedToggleRow(v, 'reason', e)"
              />
            </div>
          </div>
          <div class="row align-items-center mb-1">
            <div class="col-sm-3">
              <label>Otro motivo</label>
            </div>
            <div class="col bdl-77">
              <input
                type="text"
                class="form-control"
                v-model="leadsPayload.other_reason"
              />
            </div>
          </div>
        </div>

        <h3>Admin</h3>

        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Origen URL</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Source Medium</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Campaign Content</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Apto/No apto</label>
          </div>
          <div class="col bdl-77">
            <select name="" id="" class="fullWidth">
              <option
                v-for="(item, index) in ['', 'Apto', 'No Apto']"
                v-bind:key="index"
              >
                <!-- :selected="item == 'No Apto'" -->
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Stage</label>
          </div>
          <div class="col bdl-77">
            <div class="row">
              <div class="col-11">
                <select name="" id="" class="fullWidth">
                  <option
                    v-for="(item, index) in ['', 'Pre Lead']"
                    v-bind:key="index"
                    :selected="item == 'Pre Lead'"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <a href="javascript:void(0)">
                <CIcon :content="$options.external" />
              </a>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Fecha llamada</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Fecha email 1</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Fecha email 2</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-sm-3">
            <label>Fecha email 3</label>
          </div>
          <div class="col bdl-77">
            &nbsp;
            <!-- <input type="text" class="form-control" /> -->
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <semipolar-spinner
        :animation-duration="2000"
        :size="100"
        color="blue"
        v-if="editLeadsStatus"
        >Spinner</semipolar-spinner
      >
      <div v-if="editLeadsStatus == false">
        <button class="btn btn-default" @click="closeSessionModal()">
          Cancel
        </button>
        <button class="btn btn-primary" @click="savePayloadModal()">Ok</button>
      </div>
    </template>
  </CModal>
</template>

<script>
import { cilExternalLink } from "@coreui/icons";
import { SemipolarSpinner } from "epic-spinners";
export default {
  name: "AdminLeads",
  props: ["label", "show", "grado", "leads"],
  components: {
    SemipolarSpinner,
  },
  external: cilExternalLink,
  data() {
    return {
      rOptionsPri: [
        {
          value: "1",
          label: "Primera",
        },
        {
          value: "2",
          label: "Segunda",
        },
        {
          value: "3",
          label: "Tercera",
        },
      ],
      rOptionsY: [
        {
          value: "1",
          label: "Sí",
        },
        {
          value: "2",
          label: "No",
        },
      ],
      rOptionsFinalStatus: [
        {
          value: "1",
          label: "llocalizado",
        },
        {
          value: "2",
          label: "Colabora",
        },
        {
          value: "3",
          label: "No colabora",
        },
      ],
      hdellOptions: [
        {
          value: "1",
          label: "Contestan pero rechazan",
        },
        {
          value: "2",
          label: "Piden info sin ser consientes",
        },
        {
          value: "3",
          label: "Inquietud por COVID-19",
        },
      ],
      motivoOptions: [
        {
          value: "1",
          label: "Contestan pero rechazan",
        },
        {
          value: "2",
          label: "Piden info sin ser consientes",
        },
        {
          value: "3",
          label: "Inquietud por COVID-19",
        },
      ],
      leadsPayload: {},
      hdellRow: false,
      noColaboroRow: false,
      editLeadsStatus: false,
      //   title_: this.label ? this.label : "",
      //   show_: this.show,
    };
  },
  methods: {
    closeSessionModal() {
      // console.log(this.$props);
      this.$emit("update:show", false);
    },
    loadData() {
      this.leadsPayload = JSON.parse(JSON.stringify(this.leads));
      this.checkToggleInputRow();
    },
    checkedToggleRow(v, name, e) {
      this.leadsPayload[name] = v;
      this.checkToggleInputRow();
    },

    checkToggleInputRow() {
      const { presential, schedule, languaje, practices, title, final_status } =
        this.leadsPayload;
      if (
        presential == "1" &&
        schedule == "1" &&
        languaje == "1" &&
        practices == "1" &&
        title == "1" &&
        final_status == "2"
      ) {
        this.hdellRow = true;
      } else {
        this.hdellRow = false;
      }

      if (final_status == 3) {
        this.noColaboroRow = true;
      } else {
        this.noColaboroRow = false;
      }
    },

    savePayloadModal() {
      const { id, ...fields } = this.leadsPayload;
      const payload = {
        id,
        fields,
      };
      this.editLeadsStatus = true;
      this.$store.dispatch("a_AdminLeadsEdit", payload).then(() => {
        const response = this.$store.getters.g_adminLeadsEditStatus;
        // console.log(response);
        this.editLeadsStatus = false;
        if (response == "success") {
          this.$successToast("Lead Edited Sucessfully...");
          this.$emit("loadleads", true);
          this.closeSessionModal();
        }
      });
    },
  },
  computed: {},
  mounted() {
    // console.log(this.grado);
  },
  watch: {
    show: function (n, o) {
      if (n) {
        const { leads = null } = this.$props;
        if (leads) {
          this.loadData();
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-blue {
  background-color: #e6deff;
}
label {
  font-weight: bold;
  margin-right: 0px;
  color: #4c4c4c;
}
.bdl-77 {
  border-left: 1px solid #d7d7d7;
}
input {
  height: 26px;
}
h3 {
  font-size: 1.625rem;
  margin: 5px 0;
  color: #7c7bad;
  font-weight: bold;
}
.fullWidth {
  width: 100%;
}
</style>